import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Aloevera from "../../components/Portfolio/Aloevera"

const AloeveraPage = ({ location }) => (
  <Layout location={location}>
    <SEO title="Home" keywords={[`BMR`, `labels`]} />
    <Aloevera />
  </Layout>
)

export default AloeveraPage
